import { createSelector } from '@reduxjs/toolkit'
import type { ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { ReduxState } from 'src/store/types'
import { sweepstakeEnabledSelector } from '../appConfig/selectors'
import { getGcGeneratorEnabled } from 'store/modules/gcGenerator/selectors'

const getShopOffers = (state: ReduxState) => state.shop.offers
const getAppliedOffers = (state: ReduxState) => state.shop.applied
const getLastPurchasedOffer = (state: ReduxState) => state.shop.lastPurchasedOffer
const purchaseLimits = (state: ReduxState) => state.shop.limits

const getActivatedGameRoute = (state: ReduxState) => state.shop.activeGameRoute

const getOffersAfterPurchase = (state: ReduxState) => state.shop.offersAfterPurchase

const checkOfferType = (el: ShopOffer) => {
  return Object.prototype.hasOwnProperty.call(el, 'offerType')
}

export const getOffersSelector = createSelector(getShopOffers, (offers) => offers)
export const getAppliedOffersSelector = createSelector(getAppliedOffers, (appliedOffers) => appliedOffers)
export const getLastPurchasedOfferSelector = createSelector(
  getLastPurchasedOffer,
  (lastPurchasedOffer) => lastPurchasedOffer
)
export const purchaseLimitsSelector = createSelector(purchaseLimits, (limits) => limits)

const ALLOWED_SHOP_OFFERS_TYPES = ['one_time', 'permanent', 'personalized']

export const getCheckedShopOffersSelector = createSelector(getOffersSelector, (offers) => {
  return offers?.filter((it) => {
    if (checkOfferType(it)) {
      return ALLOWED_SHOP_OFFERS_TYPES.includes(it.offerType.toLowerCase())
    } else {
      return it
    }
  })
})

export const getSortedOffersByDesc = createSelector(getCheckedShopOffersSelector, (offers) =>
  [...offers].sort((a, b) => (a.price < b.price ? 1 : -1))
)

export const getSortedOffersByAsc = createSelector(getCheckedShopOffersSelector, (offers) =>
  [...offers].sort((a, b) => (a.price > b.price ? 1 : -1))
)

// [FYI]: need for A/B testing
// 25.09.23 updated - not used anymore for the shop page for A/B testing
export const getSortedShopOffersSelector = createSelector(
  getSortedOffersByAsc,
  getSortedOffersByDesc,
  (_: ReduxState, accountId: number) => accountId,
  (offersByAsc, offersByDesc, accountId) => (accountId % 2 === 0 ? offersByDesc : offersByAsc)
)

export const getShopOffersSelector = createSelector(getCheckedShopOffersSelector, (offers) => offers?.reverse())

export const getAppliedOffersOffersSelector = createSelector(getOffersSelector, (offers) => {
  return offers?.filter((it) => {
    if (checkOfferType(it)) {
      return it.offerType.toLowerCase() === 'one_time' || it.offerType.toLowerCase() === 'permanent'
    } else {
      return it
    }
  })
})

export const getOfferByCodeSelector = (code: string) =>
  createSelector(
    getShopOffersSelector,
    () => code,
    (offers, _code) => {
      return offers?.find((i) => i.code === _code)
    }
  )

export const getOffersByCodesSelector = (codes: string[]) =>
  createSelector(
    getShopOffersSelector,
    () => codes,
    (offers, _codes) => {
      return offers?.filter((i) => _codes.indexOf(i.code) !== -1)
    }
  )

export const getOffersBySpecificOfferField = (field: keyof ShopOffer) =>
  createSelector(
    getShopOffersSelector,
    () => field,
    (offers, _field) => {
      return offers?.filter((i) => Object.prototype.hasOwnProperty.call(i, _field))
    }
  )

export const getAllSCDiscountOffersSelector = createSelector(
  getShopOffersSelector,
  getAppliedOffersSelector,
  (offers, appliedOffers) => {
    const appliedOfferCodes = appliedOffers ? appliedOffers.map((i) => i.code) : []
    const scFirstTimeOffers =
      offers?.filter((i) => {
        return i.sweepstakeFirstOffer !== 0 && i.sweepstakeFirstOffer !== i.sweepstakeMoney
      }) || []

    return scFirstTimeOffers.filter((el) => !appliedOfferCodes.includes(el.code))
  }
)

export const getPersonalizedOffer = createSelector(
  getShopOffersSelector,
  (discountOffers: ShopOffer[]): ShopOffer | null => {
    return discountOffers.find(({ offerType }: ShopOffer) => offerType === 'personalized') || null
  }
)

export const getOffersByTagSelector = createSelector(
  getShopOffersSelector,
  (_state: ReduxState, tag: string) => tag,
  (offers, tag): ShopOffer[] | null =>
    offers?.filter(({ tags }) => tags?.join().toLowerCase().includes(tag?.toLowerCase())) || null
)

export const getSpecialOffersSelectorByTag = createSelector(
  (state: ReduxState, offers: ShopOffer[]) => offers,
  (offers): ShopOffer[] | null => offers?.filter((off) => off.tags?.includes('special_offer'))
)

export const getNoSpecialOffersSelectorByTag = createSelector(
  (_state: ReduxState, offers: ShopOffer[]) => offers,
  (offers): ShopOffer[] | null => offers?.filter((off) => !off.tags?.includes('special_offer'))
)

export const getSpecialOffers = createSelector(
  (state: ReduxState) => getSpecialOffersSelectorByTag(state, getAllSCDiscountOffersSelector(state)),
  (offers): ShopOffer[] | null => offers
)

export const getWholeSpecialOffersSelector = createSelector(
  (state: ReduxState) => getSpecialOffersSelectorByTag(state, getOffersSelector(state)),
  (offers): ShopOffer[] | null => offers
)

export const getWholeNoSpecialOffersSortedByPriceAscSelector = createSelector(
  (state: ReduxState) => getNoSpecialOffersSelectorByTag(state, getSortedOffersByAsc(state)),
  (offers): ShopOffer[] | null => offers
)

export const getSortedSpecialOffers = createSelector(getSpecialOffers, (offers) =>
  offers.sort((a, b) => (a.goldMoney > b.goldMoney ? 1 : -1))
)

export const getClosestDiscountOffer = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers[0]
})

export const getModalOffersSelector = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers.filter((i) => Boolean(i.popUpImageUrl))
})

export const getBannerOffersSelector = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers.filter((i) => Boolean(i.bannerImageUrl))
})

export const discountSCOfferAvailableSelector = createSelector(getAllSCDiscountOffersSelector, (discountOffers) => {
  return discountOffers?.length > 0
})

export const offersWereAppliedSelector = createSelector(
  getAppliedOffersSelector,
  (appliedOffers) => appliedOffers?.length > 0
)

export const isDiscountOffersAvailable = createSelector(
  sweepstakeEnabledSelector,
  offersWereAppliedSelector,
  discountSCOfferAvailableSelector,
  (scEnabled, appliedOffers, scDiscountApplied) => {
    if (scEnabled) {
      return scDiscountApplied
    }

    return !appliedOffers
  }
)

export const getRandomDiscountOfferSelector = (state: ReduxState) => {
  const discountOffers = getAllSCDiscountOffersSelector(state)

  return discountOffers?.length ? discountOffers[Math.floor(Math.random() * discountOffers.length)] : null
}

export const getMaxPriorityOffersFromOffersSelector = createSelector(
  (_state: ReduxState, offers: ShopOffer[]) => offers,
  (offers): ShopOffer[] | null => {
    if (!offers?.length) {
      return null
    }

    const maxPriority = offers.reduce((acc: ShopOffer, curr: ShopOffer) => (acc.priority < curr.priority ? acc : curr))

    return offers.filter((offer: ShopOffer) => offer.priority === maxPriority.priority)
  }
)

export const getMaxPriorityOffersWithBiggestPriceFromOffersSelector = createSelector(
  (state: ReduxState, offers: ShopOffer[]) => getMaxPriorityOffersFromOffersSelector(state, offers),
  (priorityOffers): ShopOffer[] | null => {
    if (!priorityOffers?.length) {
      return null
    }

    return [...priorityOffers].sort((a, b) => (a.price < b.price ? 1 : -1)) || null
  }
)

export const getMaxPrioritySpecialOffersWithBiggestPriceSelector = createSelector(
  (state: ReduxState) =>
    getMaxPriorityOffersWithBiggestPriceFromOffersSelector(state, getWholeSpecialOffersSelector(state)),
  (priorityOffers): ShopOffer[] | null => priorityOffers
)

export const getOffersWith49d99Price = createSelector(getOffersSelector, (offers): ShopOffer[] | null => {
  return offers?.length ? offers.filter((offer) => offer.price === 49.99) : null
})

export const getMaxPriorityOfferFromOffersSelector = createSelector(
  (state: ReduxState, offers: ShopOffer[]) => getMaxPriorityOffersFromOffersSelector(state, offers),
  (maxPriorityOffers): ShopOffer | null => {
    return maxPriorityOffers?.length ? maxPriorityOffers[Math.floor(Math.random() * maxPriorityOffers.length)] : null
  }
)

export const getMaxPriorityOfferSelector = createSelector(
  (state: ReduxState) => getMaxPriorityOfferFromOffersSelector(state, getOffersSelector(state)),
  (maxPriorityOffer): ShopOffer | null => maxPriorityOffer
)

export const getMaxPriorityFirstPurchaseOfferSelector = createSelector(
  (state: ReduxState) => getMaxPriorityOffersFromOffersSelector(state, getOffersByTagSelector(state, '1st purchase')),
  (maxPriorityOffers): ShopOffer | null => (maxPriorityOffers?.length ? maxPriorityOffers[0] : null)
)

export const getMaxPriorityOrRandomModalOfferSelector = createSelector(
  (state: ReduxState) => getMaxPriorityOfferFromOffersSelector(state, getModalOffersSelector(state)),
  (maxPriorityOffer): ShopOffer | null => maxPriorityOffer
)

export const isOffersAvailableSelector = createSelector(
  getModalOffersSelector,
  isDiscountOffersAvailable,
  sweepstakeEnabledSelector,
  (SCModalOffers, isFirstPurchaseNotUsed, sweepstakeEnabled) => {
    return sweepstakeEnabled && SCModalOffers?.length > 0 && isFirstPurchaseNotUsed
  }
)

export const getSpecialOfferAmount = createSelector(getSpecialOffers, (offers) => offers?.length || 0)

export const lowOnFundsOfferSelector = createSelector(
  getSortedSpecialOffers,
  (state: ReduxState, accountId: number) => getSortedShopOffersSelector(state, accountId),
  (specialOffers, shopOffers) => {
    if (specialOffers?.length > 0) {
      return specialOffers
    }

    return shopOffers
  }
)

export const getOffersAfterPurchaseSelector = createSelector(getOffersAfterPurchase, (offers) => offers)

export const getIsShowOffersAfterPurchaseSelector = createSelector(
  getOffersAfterPurchase,
  (offers) => offers?.length > 0
)

export const getActiveGameRouteSelector = createSelector(
  getActivatedGameRoute,
  (activeGameRoute: string) => activeGameRoute
)

export const getIsActivatedGameSelector = createSelector(
  getActiveGameRouteSelector,
  (activeGameRoute: string) => !!activeGameRoute
)

export const getIsSpecialOffersVisible = createSelector(
  getGcGeneratorEnabled,
  (isGcGeneratorEnabled) => !isGcGeneratorEnabled
)

/*
 * 1. [1 offer] - first_purchase offer or offer with the closest price of the last_purchase offer
 * 2.a [2,n offers] - special_offer offers sorted by max priority and highest price (skip [1 offer])
 * 2.b [2,n offers] - if special_offer doest exist, take no_special_offer offers with the closest price of [1 offer] (skip [1 offer])
 * */
export const getQuickPurchaseOffersSelector = createSelector(
  (_state: ReduxState, size?: number) => size,
  getMaxPriorityFirstPurchaseOfferSelector,
  getLastPurchasedOfferSelector,
  offersWereAppliedSelector,
  getSortedOffersByAsc,
  getMaxPrioritySpecialOffersWithBiggestPriceSelector,
  getWholeNoSpecialOffersSortedByPriceAscSelector,
  (
    size,
    firstPurchaseOffer,
    lastPurchaseOffer,
    offersWereApplied,
    sortedOffersByAsc,
    maxPrioritySpecialOffersWithBiggestPrice = [],
    noSpecialOffersSortedByPrice = []
  ): ShopOffer[] | null => {
    const isFirstOfferExisted = !offersWereApplied && firstPurchaseOffer
    const offerForSorting = isFirstOfferExisted ? firstPurchaseOffer : lastPurchaseOffer

    const getFilteredOffers = (offers: ShopOffer[], offerToCondition: ShopOffer): ShopOffer[] => {
      const getIsOfferNoFirstOfferAndWithClosestPrice = ({ code, price }: ShopOffer) => {
        return offerToCondition?.code !== code && Number(offerToCondition?.price) < price
      }

      return offers?.filter(getIsOfferNoFirstOfferAndWithClosestPrice) || []
    }

    // [1 offer] --- start
    const [offerWithClosestPrice] = getFilteredOffers(sortedOffersByAsc, offerForSorting)
    const firstOffer = isFirstOfferExisted ? firstPurchaseOffer : offerWithClosestPrice
    // [1 offer] --- end

    // [2,n offers] --- start
    const maxPrioritySpecialOffersWithBiggestPriceFiltered: ShopOffer[] =
      maxPrioritySpecialOffersWithBiggestPrice?.filter(({ code }) => offerForSorting?.code !== code) || []

    // need skip last_purchase offer and [1 offer]
    const noSpecialOffersFiltered = getFilteredOffers(
      getFilteredOffers(noSpecialOffersSortedByPrice, offerForSorting),
      firstOffer
    )
    // [2,n offers] --- end

    const offers: ShopOffer[] = [
      firstOffer,
      ...maxPrioritySpecialOffersWithBiggestPriceFiltered,
      ...noSpecialOffersFiltered,
    ].filter(Boolean)

    return size ? offers.slice(0, size) : offers
  }
)

export const getLastPurchaseOfferForLowOnFundsDialogSelector = createSelector(
  getSortedOffersByAsc,
  getLastPurchasedOfferSelector,
  (offers, lastPurchaseOffer): ShopOffer | null => {
    if (!offers?.length || !lastPurchaseOffer) {
      return null
    }

    const isExistLastPurchaseOffer = offers.some(({ code }) => code === lastPurchaseOffer.code)

    if (isExistLastPurchaseOffer) {
      return lastPurchaseOffer
    }

    const filteredOffers = offers.filter(({ price }) => price > lastPurchaseOffer.price) || []
    const [offerWithClosestPriceForLastPurchaseOffer = null]: ShopOffer[] = [...filteredOffers].sort((a, b) => {
      if (a.price === b.price) {
        return a.priority < b.priority ? -1 : 1
      }

      return 0
    })

    return offerWithClosestPriceForLastPurchaseOffer
  }
)
