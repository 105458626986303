import { createSelector } from '@reduxjs/toolkit'
import type { PaymentProviders } from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import type { ReduxState } from 'store/types'
import { getIsPaymentProvidersUpdateNeededSelector } from '@patrianna/shared-store/payments/store/selectors'

const appInitialized = (state: ReduxState) => state.appConfig.init
const getPasswordPolicy = (state: ReduxState) => state.appConfig.passwordPolicy
const getSweepstakeEnabled = (state: ReduxState) => state.appConfig.sweepstakeEnabled
const getForcementMode = (state: ReduxState) => state.appConfig.forcement
const getIsPWAMessageOpen = (state: ReduxState) => state.appConfig.isPWAMessageOpen
const getLocationData = (state: ReduxState) => state.appConfig.location
const getPurchaseProviders = (state: ReduxState) => state.appConfig.paymentProviders?.purchaseProviders
const getWithdrawProviders = (state: ReduxState) => state.appConfig.paymentProviders?.withdrawProviders
const getSoftKycRequiredEnabled = (state: ReduxState) => state.appConfig.softKycRequiredEnabled

export const getSoftKycAutoCompleteAddressEnabled = (state: ReduxState) => state.appConfig.softKycAutoCompleteAddress

export const getPurchaseProvidersSelector = createSelector(
  getPurchaseProviders,
  (purchaseProviders): PaymentProviders['purchaseProviders'] | null => purchaseProviders || null
)
export const getWithdrawProvidersSelector = createSelector(
  getWithdrawProviders,
  (withdrawProviders) => withdrawProviders || {}
)

export const isCashProvidersAvailableSelector = createSelector(
  getWithdrawProvidersSelector,
  (withdrawProviders) =>
    Boolean(withdrawProviders?.nuvei) || Boolean(withdrawProviders?.skrill) || Boolean(withdrawProviders?.masspay)
)

export const getIsSoftKycRequiredEnabledSelector = createSelector(
  getSoftKycRequiredEnabled,
  (isSoftKycRequiredEnabled) => isSoftKycRequiredEnabled
)

export const isAppInitializedSelector = createSelector(appInitialized, (initialized: boolean) => initialized)
export const getIsPWAMessageOpenSelector = createSelector(getIsPWAMessageOpen, (isOpen) => isOpen)

// payment apps
export const getPayWithMyBankConfigSelector = createSelector(getPurchaseProviders, (apps) => apps?.payWithMyBank)
export const getSpreedlyIdSelector = createSelector(getPurchaseProviders, (apps) => apps?.spreedly?.id || '')

export const getGooglePayConfigSelector = createSelector(getPurchaseProviders, (apps) => {
  if (apps?.spreedlyGooglePay?.merchantId) {
    return {
      _provider_: 'spreedly_google_pay',
      ...apps?.spreedlyGooglePay,
    }
  }

  if (apps?.fiservGooglePay?.merchantId) {
    return {
      _provider_: 'fiserv_google_pay',
      ...apps?.fiservGooglePay,
    }
  }

  return null
})

export const getApplePayConfigSelector = createSelector(getPurchaseProviders, (apps) => {
  if (apps?.spreedlyApplePay?.merchantId) {
    return {
      _provider_: 'spreedly_apple_pay',
      ...apps?.spreedlyApplePay,
    }
  }

  if (apps?.fiservApplePay?.merchantId) {
    return {
      _provider_: 'fiserv_apple_pay',
      ...apps?.fiservApplePay,
    }
  }

  return null
})

export const getFiservPaymentId = createSelector(getPurchaseProviders, (apps) => apps?.fiserv?.id || '')
// redeem apps
export const getPrizeoutIdSelector = createSelector(getWithdrawProviders, (apps) => apps?.prizeout?.id)
export const getPrizeoutKeySelector = createSelector(getWithdrawProviders, (apps) => apps?.prizeout?.key)

export const getPasswordPolicySelector = createSelector(getPasswordPolicy, (pPolicy) => pPolicy)

export const sweepstakeEnabledSelector = createSelector(getSweepstakeEnabled, (sweepstakeEnabled) => sweepstakeEnabled)

export const getLocationDataSelector = createSelector(getLocationData, (location) => location)

export const getForcementModeSelector = createSelector(getForcementMode, (forcement) => forcement)

// @TODO replace isSCVisibleSelector by sweepstakeEnabledSelector
export const isSCVisibleSelector = createSelector(sweepstakeEnabledSelector, (scEnabled) => {
  return scEnabled
})

export const isGetOrUpdatePaymentProvidersSelector = createSelector(
  getPurchaseProvidersSelector,
  getIsPaymentProvidersUpdateNeededSelector,
  (providers, isShouldUpdate) => !providers || isShouldUpdate
)
