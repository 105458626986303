import { createSelector } from '@reduxjs/toolkit'
import type { Currencies, Currency } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { ReduxState } from 'src/store/types'

const getActiveCurrencyId = (state: ReduxState) => state.currencies.activeCurrencyId
const getActiveModalCurrencyId = (state: ReduxState) => state.currencies.activeModalCurrencyId
const getAllCurrencies = (state: ReduxState) => state.currencies.allCurrencies

export const getActiveCurrencyIdSelector = createSelector(getActiveCurrencyId, (activeCurrencyId) => activeCurrencyId)

export const getActiveModalCurrencyIdSelector = createSelector(getActiveModalCurrencyId, (currencyId) => currencyId)

export const getAllCurrenciesSelector = createSelector(getAllCurrencies, (allCurrencies) => allCurrencies)
export const isCurrenciesAvailableSelector = createSelector(getAllCurrencies, (currencies) =>
  Boolean(currencies?.length)
)

export const getSweepstakeCurrencySelector = createSelector(
  getAllCurrenciesSelector,
  (allCurrencies) => allCurrencies.find((i) => i.currency === 'SC') || null
)

export const getGoldCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'GC')
)

export const getUSDCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'USD')
)

export const isSweepstakeCurrencyActiveSelector = createSelector(getActiveCurrencyIdSelector, (id) => id === 'SC')

export const getCurrencyInitializedSelector = createSelector(
  getActiveCurrencyIdSelector,
  (activeCurrencyId) => activeCurrencyId !== null
)

export const getBalanceByIDSelector = createSelector(
  getAllCurrenciesSelector,
  (_: ReduxState, currencyId: Currencies) => currencyId,
  (allCurrencies, currencId) => allCurrencies.find((el) => el.currency === currencId)
)

export const getCurrentBalanceSelector = createSelector(
  getAllCurrenciesSelector,
  getActiveCurrencyIdSelector,
  (allCurrencies, activeCurrency) => {
    return allCurrencies.find((i: Currency) => i.currency === activeCurrency)
  }
)
