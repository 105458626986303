'use client'

import type { RTTextNode } from '@prismicio/types'
import type { ReactNode } from 'react'
import { createContext, memo, useCallback, useContext, useEffect, useState } from 'react'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { getTranslationText } from './utils/getTranslationText'

type Translation = Record<string, string | RTTextNode[]>
type Translations = Record<string, Translation>

const TranslationsContext = createContext<(key: string) => any>(null as any)
TranslationsContext.displayName = 'TranslationsContext'

interface TranslationsProviderProps {
  children: ReactNode
  translations: Translations
}

function TranslationsProvider(props: TranslationsProviderProps) {
  const { children, translations = {} } = props
  const [isDebugMode, setDebugMode] = useState(false)

  useEffect(() => {
    setDebugMode(!!getCookie('PRISMIC_KEYS', document.cookie))
  }, [])

  const t = useCallback(
    (key: string) => getTranslationText(key, translations, isDebugMode),
    [translations, isDebugMode]
  )

  return <TranslationsContext.Provider value={t}>{children}</TranslationsContext.Provider>
}

const TranslationsProviderMemo = memo(TranslationsProvider)

function useTranslation() {
  const t = useContext(TranslationsContext)

  if (t === undefined) {
    throw new Error('useTranslation must be used within a TranslationsProvider')
  }

  return t
}

export { TranslationsProviderMemo as TranslationsProvider, useTranslation }
